$blacklakeGreen: #02b980;

.calendarDisplayHeader {
  height: 54px;
  background-color: #fafafa;
  display: flex;
  justify-content: flex-end;
  padding: 15px 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.calendarTitleContainer {
  position: absolute;
  top: 15px;
  left: 13px;
  display: flex;
  .calendarDisplayTitle {
    margin-right: 8px;
  }
}

.calendarHeaderIcon {
  padding-top: 5px;
  cursor: pointer;
}

.calendarDisplayBody {
  background-color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .noPreviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .noPreviewHint {
      font-family: PingFangSC-Regular, sans-serif;
      color: rgba(0, 0, 0, 0.25);
      font-size: 16px;
    }
  }
  :global {
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
      text-align: center;
    }
    .ant-picker-calendar-full .ant-picker-panel {
      padding: 0 8px;
    }
  }
}

.calendarCell {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 87px;
  align-items: center;
  justify-content: center;
  margin: 3px;
  position: relative;
  .calendarCellDate {
    font-family: PingFangSC-Medium, sans-serif;
    font-size: 24px;
    line-height: 24px;
    position: relative;
    height: 30px;
    padding-top: 7px;
  }
  .calendarCellShift {
    white-space: nowrap;
    max-width: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.selected {
    border: 2px solid $blacklakeGreen;
    border-radius: 4px;
  }
  .specialDayTag {
    position: absolute;
    border: 1px solid $blacklakeGreen;
    border-radius: 2px;
    color: $blacklakeGreen;
    background-color: transparent;
    padding: 0 5px;
    top: 5px;
    left: 5px;
    font-size: 12px;
    max-width: calc(100% - 10px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
