@mixin chartsContainer {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  height: 100%;

  .monitorChart {
    position: relative;
    .paramCard {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 7px;
      background-color: #fafafa;
      .noViewWarning {
        width: 100%;
        position: absolute;
        top: 0;
        padding: 5px;
      }
    }
    .iconBox {
      display: none;
      position: absolute;
      top: 10px;
      right: 10px;
      color: black;
      .switchViewBox {
        display: flex;
      }
      :global button {
        background-color: #efefef;
      }
    }
    &:hover .iconBox {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.chartsContainer11 {
  @include chartsContainer;
  grid-template-columns: 100%;
}

.chartsContainer21 {
  @include chartsContainer;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-template-rows: 100%;
}

.chartsContainer22 {
  @include chartsContainer;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-template-rows: repeat(2, calc(50% - 5px));
}

.chartsContainer32 {
  @include chartsContainer;
  grid-template-columns: repeat(3, calc(33.3% - 7px));
  grid-template-rows: repeat(2, calc(50% - 5px));
}

.chartsContainer33 {
  @include chartsContainer;
  grid-template-columns: repeat(3, calc(33.3% - 7px));
  grid-template-rows: repeat(3, calc(33.3% - 7px));
}
