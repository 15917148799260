// 主色
$blacklakeGreen: #02b980;

// 背景色
$menuBlack: #303030;
$menuHover: #595959;
$submenu: #f0f0f0;
$menuGreen: #e1faee;
// 白色系
$white65: rgba(255, 255, 255, 0.65);
$white85: rgba(255, 255, 255, 0.85);
// 黑色系
$black02: rgba(0, 0, 0, 0.02);
$black45: rgba(0, 0, 0, 0.45);
$black65: rgba(0, 0, 0, 0.65);
$black85: rgba(0, 0, 0, 0.85);

$greyContent: #f5f5f5;
$borderColor: #f0f0f0;
$lightBackground: #fafafa;
$listHeader: #e4e4e4;
