.controlInputFullWidth {
  :global {
    .ant-form-item-control-input-content {
      width: 100%;
    }
  }
}

.labelBottom {
  margin-bottom: 1rem;
}
.noticeUnderLabel {
  color: #8c8c8c;
  font-size: 12px;

  & > span {
    margin-right: 5px;
  }
}

.controlValueWrap {
  max-width: 118px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
