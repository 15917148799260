.queryForm {
  padding-left: 20px !important;
  :global {
    .ant-form-item {
      .ant-select,
      .ant-input {
        width: 180px;
      }
    }
  }
}
