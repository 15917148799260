.stepEditWrap {
  height: 100%;
  :global {
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
  }

  .stepEditCon {
    height: calc(100% - 53px);
  }
  .footer {
    padding: 10px 0;
    background-color: #ffffff;
    border-top: 1px solid hsla(0, 0%, 69.4%, 0.1803921568627451);
    box-shadow: 0 -3px 5px -6px #b9b6b6;
  }
  :global {
    .react-resizable {
      // overflow-y: auto;
      background-color: #ffffff !important;
    }
  }
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .treeWrap {
    padding: 24px;
    min-width: 146px;
    height: 100%;
    // overflow-y: auto;
    overflow-y: scroll;
    // 禁止左侧滚动
    // overflow-x: hidden;
    .treeSearch {
      margin: 16px 0;
    }
    :global {
      .bl-descriptionTitle {
        line-height: 1.5;
        font-size: 16px;
        font-weight: 500;
        .title-left-border {
          display: inline-flex;
          margin-right: 8px;
          width: 2px;
          height: 16px;
          background-color: #02b980;
          border-radius: 1px;
          vertical-align: middle;
        }
        .title-left {
          vertical-align: middle;
        }
      }
    }
    .textExplise {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .btnGroup {
      // display: block;
      display: flex;
      margin-bottom: 16px;
      :global {
        .ant-space {
          flex: 1;
          justify-content: space-between;
        }
        .ant-space-item {
          flex: 1;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 8px;
          }
        }
        .ant-btn {
          width: 100%;
          // margin-right: 16px;
          // margin-bottom: 16px;
          // width: 118px;
          // max-width: 118px;
        }
      }
    }
  }
}
