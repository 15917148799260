.sopEditWrap {
  margin: -16px;
  height: 100%;
  .stepsCon {
    padding: 16px;
    border-top: 1px solid #f0f0f0;
    background-color: #ffffff;
  }
  :global {
    .ant-steps {
      width: 70%;
      text-align: center;
    }
  }
  .sopEditCon {
    padding: 16px;
    height: calc(100% - 32px);
  }
}
