.tabPanel {
  width: 100%;
  :global {
    .ant-tabs .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

.panelContent {
  border: 1px solid rgb(240, 240, 240);
  background: #ffffff;
  border-top: none;
  padding: 20px;
}

.titleContent {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 10px 0px 10px 10px;
}

.authTitle {
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

.dataPrivilegeContentHeader {
  display: flex;
  text-align: center;
  align-items: center;
  height: 47px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid #f0f0f0;
  font-weight: bold;
}

.dataPrivilegeContentItem {
  display: flex;
  text-align: center;
  align-items: center;
  min-height: 47px;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.dataPrivilegeContentFooter {
  height: 47px;
  margin-top: 10px;
}

.selectWrap {
  flex: 1;
  padding: 0 10px;
}
