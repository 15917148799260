.materialTable {
  margin-top: 10px;
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-table-footer {
      padding: 8px 16px;
      background-color: #fff;
      .batchIcon {
        box-sizing: border-box;
        text-align: center;
        flex: 1;
        cursor: pointer;
        border: 1px dashed #d9d9d9;
        background-color: #fff;
        margin: 8px 0px 8px 0px;
        &:first-child {
          margin-right: 16px;
        }
      }
      .batchIcon:hover {
        color: #22c78d;
        border-color: #22c78d;
      }
      .add {
        box-sizing: border-box;
        text-align: center;
        flex: 1;
        cursor: pointer;
        border: 1px dashed #d9d9d9;
        background-color: #fff;
        margin: 8px 0px 8px 0px;
        padding: 5px 0;
      }
      .add:hover {
        color: #22c78d;
        border-color: #22c78d;
      }
    }
  }
}

.addMaterialModal {
  margin-top: -100px !important;
  :global {
    .ant-modal-footer {
      text-align: center;
    }
    .ant-modal-body {
      padding: 0;
      height: 800px;
    }
  }
}

.queryForm {
  padding-left: 20px !important;
  padding-top: 20px !important;
  :global {
    .ant-form-item {
      .ant-select,
      .ant-input {
        width: 180px;
      }
    }
  }
}
