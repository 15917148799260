$blacklakeGreen: #02b980;

.toolBar {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.helpPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.helpTipList {
  padding-left: 16px;
}

.helpIcon {
  margin-left: 5px;
  &:hover :global {
    svg {
      fill: $blacklakeGreen;
      color: $blacklakeGreen;
    }
  }
}
