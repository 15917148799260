$blacklakeGreen: #02b980;
.scheduleTabs {
  :global {
    .ant-tabs-nav-wrap {
      padding: 0 19px;
      .ant-tabs-tab .anticon {
        margin-right: 0;
      }
    }
    .ant-tabs-nav {
      margin: 0 0 12px 0 !important;
    }
    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      max-height: 193px;
      overflow-y: auto;
    }
  }
  .shiftListItem {
    height: 32px;
    padding: 0 19px;
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
      border: 2px solid #f0f0f0;
      padding: 0 17px;
    }
    &:focus {
      background-color: #f0f0f0;
      border: 2px solid $blacklakeGreen;
      padding: 0 17px;
    }
    .shiftLabel {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
