.calendarTooltipContent {
  width: 200px;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.65);

  .calendarTooltipDetail {
    max-height: 180px;
    overflow: scroll;
    .calendarTooltipItem {
      line-height: 28px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }

    .calendarPeriodContainer {
      display: flex;
    }
  }
  .calendarTooltipButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
}
