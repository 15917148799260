.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  padding: 8px;

  background: #ffffff;
  border: 1px solid rgba(217, 217, 217, 1);
  transition: all 0.3s linear;
  border-radius: 4px;

  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
}

.info {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  margin-right: 20px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #02b980;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.desc {
  color: #c4c5c5;
}
