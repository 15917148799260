.ant-list-item:hover {
  background-color: #eee;
}

.ant-list-item {
  padding: 8px !important;
}

.ant-list-items .selected {
  background-color: rgba(2, 185, 128, 0.2);
}
