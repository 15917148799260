@import 'src/styles/color/variable.scss';

.groupContent {
  background: $greyContent;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  .tableAddButton {
    width: 100%;
  }
  .groupTitleContent {
    width: 100%;
    position: relative;
    display: flex;
    span[role='img'] {
      line-height: 32px;
    }
  }
  .tableAddButton {
    width: 100%;
  }
}

.formulaInput {
  margin-right: 8px !important;
}
