.filterContent {
  display: flex;
  padding: 10px 0px;
  margin-bottom: 5px;
  height: 100%;
  :global {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
  .filterRightContent {
    flex: 1;
    border: none;
    height: 100%;
    margin-left: 20px;
  }
}
.filterType {
  width: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
  // margin-right: 8px;
  .filterLine {
    width: 1px;
    height: 50%;
    background-color: #a3a5a4;
    margin: 0;
  }
}
.titleContent {
  display: flex;
  height: 40px;
  background-color: #d4d5d5;
  box-sizing: border-box;
  width: 100%;
  .titleRow {
    flex: auto;
  }
  .titleItem {
    text-align: center;
    padding: 6px 10px;
    border: 1px solid #d4d5d5;
    background-color: #f0f0f0;
    box-sizing: border-box;
    line-height: 24px;
  }
}

.innerTableLayout {
  border-bottom: 1px solid #d4d5d5;
  margin-left: -12px;
  margin-top: 10px;
}

.addItemButton {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-top: 10px;
}

.filterCondition {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  display: flex;
  justify-content: space-around;
  .filterSelect {
    flex: 1;
    width: 200px;
    margin: 5px;
  }
}
.operation {
  padding: 6px 20px;
  display: inline-flex;
  justify-content: space-between;
  flex: 0 0 102px;
  .operationItem {
    flex: 1;
    width: 20px;
    cursor: pointer;
  }
}

.formListItemRow {
  flex: 1;
  // :last-child {
  //   border-bottom: none;
  // }
}
.conditionItem {
  border-bottom: 1px solid #d4d5d5;
  padding: 10px 0 10px 0;
  box-sizing: border-box;
  flex: 1;
}
