.li {
  cursor: pointer;
  padding: 5px;
  transition: all 0.1s;

  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  &.active {
    background: #e1faee;
    font-size: 14px;
    color: #02b980;
    line-height: 22px;
    font-weight: bold;
  }

  &.disable {
    pointer-events: none;
    cursor: not-allowed;
    background: #fafafa;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 22px;
  }
}
