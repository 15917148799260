.timeOutInfo {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.timeOutBlack {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 0px;
  margin: 20px 0px;
}
