.blDelConfirm {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.blDelConfirmContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
