.stepWBox {
  position: relative;
  .stepWrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    :global {
      .ant-steps {
        width: 50%;
      }
    }
  }

  .content {
    height: calc(100vh - 176px);
    background-color: #fff;
  }

  .footer {
    z-index: 2;
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: flex;
    height: 52px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    .button {
      margin-right: 16px;
    }
    border-top: 1px solid #f0f0f0;
  }
}
