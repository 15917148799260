.avatarWrapper {
  & > span:not(:first-child) {
    margin-left: 15px;
  }
  .attachUpload {
    max-width: 360px;
  }
}

.avatarUpload {
  display: flex;
  align-items: flex-end;
  position: relative;

  .avatarUploadBtn {
    opacity: 0;
    width: 86px;
    position: absolute;
    height: 86px;
    text-align: center;
    border-radius: 43px;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.45);
      .avatarBtn {
        color: #fff;
      }
    }
    .avatarBtn {
      width: 86px;
      height: 86px;
      border-radius: 43px;
      text-align: center;
      line-height: 86px;
      cursor: pointer;
    }
  }
}
