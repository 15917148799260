.image-view-wrap {
  background-color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 900px;
  overflow: scroll;
  padding: 50px;
  .image-actions {
    position: absolute;
    bottom: 50px;
    background-color: rgba(37, 37, 37, 0.85);
    width: 360px;
    display: flex;
    font-size: 16px;
    user-select: none;
    justify-content: space-around;
    border-radius: 10px;
    .zoom-number {
      color: #fff;
      margin: 0 20px;
    }
    a {
      font-size: 18px;
      display: inline-block;
      padding: 5px 3px;
      color: #fff;
    }
  }
  .image-view {
    user-select: none;
    transition: transform 350ms cubic-bezier(0.6, 0, 0.1, 1),
      opacity 350ms cubic-bezier(0.6, 0, 0.1, 1), clip-path 350ms cubic-bezier(0.6, 0, 0.1, 1);
  }
}
