@import 'src/styles/color/variable.scss';

.ant-layout-header {
  background-color: #fff !important;
  padding: 0px !important;
}
.ant-layout {
  background-color: #f0f6f4 !important;
}

.ant-layout-content {
  padding: 16px;
}

.bl-sub-menu {
  margin-right: -2px;
  top: 0 !important;
  bottom: 0 !important;
  background: #fff !important;
  height: 100vh;
  box-shadow: none !important;
}

.dark-sider {
  background-color: $menuBlack !important;
  user-select: none;
}

.dark-sider .ant-menu {
  background-color: $menuBlack !important;
  border-color: $menuBlack;
}
.dark-sider .ant-menu-item.ant-menu-item-selected,
.ant-layout-sider .ant-menu-submenu.ant-menu-submenu-selected {
  background: #fff !important;
  .ant-menu-submenu-arrow,
  a,
  .ant-menu-submenu-title {
    color: $black65 !important;
  }
  &:hover {
    background: #fff !important;
    color: $black65 !important;
    .ant-menu-submenu-arrow,
    a,
    .ant-menu-submenu-title {
      color: $black65 !important;
    }
  }
}

.dark-sider .ant-menu-item,
.dark-sider .ant-menu-submenu {
  .ant-menu-submenu-arrow,
  a,
  .ant-menu-submenu-title {
    color: $white65;
  }
  &:hover {
    background: $menuHover !important;
    color: #fff !important;
    .ant-menu-submenu-arrow,
    a,
    .ant-menu-submenu-title {
      color: #fff !important;
    }
  }
}

.menu_level_2 {
  height: calc(100% - 64px);
  overflow: auto;
  .ant-menu-item-group-title {
    color: $black85;
    font-size: 16px;
  }
  .ant-menu-item.ant-menu-item-selected {
    background: $menuGreen;
    border-right: 2px solid #02b980;
    color: $black85;
    font-weight: 500;
  }
  .ant-menu-item:hover {
    background: $submenu;
    a,
    .ant-menu-title-content {
      color: $black65;
    }
  }
  .ant-menu-title-content {
    color: $black65;
    font-size: 16px;
  }
}

.ant-menu-submenu-popup {
  border-right: 1px solid #f0f0f0;
}

.antd-popup-menu-item {
  padding: 0 16px 0 38px !important;
}
.antd-popup-menu-item.ant-menu-item-selected a,
.antd-popup-menu-item.ant-menu-item-selected a:hover {
  color: #000 !important;
}

.ant-menu-submenu-popup > .ant-menu {
  box-shadow: none;
  border-right: 0 !important;
}

.ant-page-header {
  padding: 0 !important;
}

.ant-page-header-content {
  background-color: #fff;
  height: 100%;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.treeSearch {
  margin: 0px 0px 18px 0px;
}

.tree {
  padding: 12px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-pro-page-container-warp {
  background: rgba(0, 0, 0, 0) !important;
}

.ant-pro-sider-extra {
  padding: 0;
}

.ant-layout-sider-children {
  border-right: solid #eee 1px;
}

.ant-tabs-tab .ant-badge {
  color: inherit;
}
