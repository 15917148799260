.overall-container {
  display: flex;
  gap: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.left-part {
  flex-shrink: 0;

  @media screen and (max-width: 1280px) {
    width: 672px;
  }
  @media screen and (min-width: 1281px) and (max-width: 1439px) {
    width: calc(60vw - 96px);
  }
  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    width: calc(76.67vw - 336px);
  }
}

.module-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}

.module-container {
  padding: 24px;
  background-color: #fff;
}

.basic-info-top {
  margin-top: 18px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: #595959;
}
.base-info-top-item {
  margin-right: 24px;
}
.basic-info-effective-date {
  margin-top: 8px;
  color: #8c8c8c;
  text-align: right;
}
.dashed-box {
  margin-left: 8px;
  width: 36px;
  height: 36px;
  border: 1px dashed #02b980;
  border-radius: 4px;
  font-weight: 500;
  font-size: 24px;
  color: #02b980;
  display: flex;
  justify-content: center;
  align-items: center;
}

.available-info-container {
  margin-top: 52px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.available-info-card {
  padding: 32px;
  background: linear-gradient(180deg, #fafafa, #ffffff 99%);
  border-radius: 4px;

  @media screen and (max-width: 1280px) {
    width: 304px;
  }

  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    width: calc(30vw - 80px);
  }

  @media screen and (min-width: 1441px) {
    width: 352px;
  }
}
.available-info-title {
  font-size: 20px;
  font-weight: 500;
}
.available-info-lower-part {
  margin-top: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.available-info-icon {
  width: 108px;
  height: 108px;
  object-fit: contain;
}

.allowance-item-container {
  border: 1px solid #efefef;
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.allowance-item-upper {
  background-color: #fafafa;
  height: 63px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.allowance-item-lower {
  padding: 15.5px 24px;
  color: #8c8c8c;
}

.right-part {
  min-width: 352px;
  flex: 1;
  background-color: #fff;
}
.system-use-item {
  &:not(:last-of-type) {
    margin-bottom: 76px;
  }
}
.system-use-item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-use-item-available {
  color: #02b980;
}
