.logSearch {
  display: flex;
  margin-bottom: 10px;
  .search {
    flex: 1;
  }
  .prompt {
    flex: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: #8c8c8c;
  }
}
.logSpan {
  margin-right: 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.logDo {
  margin-right: 5px;
  display: inline-block;
}

.logTime {
  margin-right: 20px;
  display: inline-block;
  flex: 2;
}

.logEdit {
  display: flex;
}
.logInfo {
  flex: 6;
}
