.resourceMonitorChart {
  height: 100%;
  display: flex;
  flex-direction: column;
  .monitorHeader {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    .monitorHeaderSelector {
      flex: 1;
    }
    .monitorHeaderLabel {
      margin-right: 5px;
    }
  }
  .monitorContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 53px);
    .exitFullScreenIcon {
      position: absolute;
      left: 0;
      top: 20px;
      background-color: #efefef;
    }
    &.fullscreen.mouseStop {
      cursor: none;
      * {
        cursor: none !important;
      }
      .exitFullScreenIcon {
        display: none;
      }
    }
  }
}

.fullScreenIcon {
  margin-left: 10px;
}
