@import 'src/styles/color/variable.scss';

.resize-handle {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: -2px;
  background: transparent;
  cursor: col-resize;
  padding: 0 2px;
  user-select: none;
  &:hover {
    background-color: $blacklakeGreen;
  }
}
.resize-handle-draggling {
  background-color: $blacklakeGreen;
}
.resize-component-wrapper {
  height: 100%;
  overflow: visible;
}
.resize-shade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  cursor: col-resize;
  z-index: 2;
}
.resize-cursor-left-boundary {
  cursor: e-resize;
}
.resize-cursor-right-boundary {
  cursor: w-resize;
}
