@import 'src/styles/color/variable.scss';

.draggable-list {
  flex: 0;
  position: relative;

  .draggable-list-item-wrapper {
    padding: 0 !important;
    border-bottom: 1px solid $borderColor;
    user-select: none;
    height: 46px;

    &.drop-over-downward {
      border-bottom: 2px dashed #22c78d !important;
    }
    &.drop-over-upward {
      border-top: 2px dashed #22c78d !important;
    }
  }
  .draggable-list-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
