.transferModal {
  :global {
    .ant-modal-footer {
      text-align: center;
    }
  }
}

.tableTransfer {
  :global {
    .ant-table-selection-column {
      padding-left: 12px;
    }

    .ant-table-pagination {
      margin: 16px 12px 4px !important;
    }
  }
}
