.shiftTable {
  width: '80%';
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-table-filter-trigger-container {
      right: 15px;
    }
  }
}
