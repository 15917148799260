.matcher-container {
  display: flex;
  width: 560px;
  border: 1px solid #f0f0f0;
}

.matcher-part {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 50%;
}

.matcher-header {
  padding: 12px 24px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  background-color: #f0f0f0;
}

.matcher-item {
  padding: 12px 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matcher-content {
  display: block !important;
  position: relative;
  margin: 0;
  height: calc(100vh - 400px);
  min-height: 200px;
  max-height: 508px;
  overflow-x: hidden;
  overflow-y: auto;

  :global {
    .ant-checkbox-group-item {
      display: flex;
      margin-right: 0;
      padding: 12px 24px;

      > span + span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.matcher-part-left {
  .matcher-header {
    border-right: 1px solid #e4e4e4;
  }
  .matcher-content {
    background-color: #fafafa;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #f0f0f0;
    }
  }

  .matcher-item {
    position: relative;
    cursor: pointer;

    &:hover,
    &.selected {
      background-color: #fff;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #fff;
        z-index: 1;
      }
    }
  }
}
