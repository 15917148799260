.calendarTypeHintFix {
  font-size: 8px;
  color: orange;
  padding-left: 24px;
}

.calendarTypeHintFlex {
  font-size: 8px;
  color: orange;
  padding-left: 120px;
}

.fitRangeAndCalendar {
  display: flex;
  height: 652px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  :global {
    .ant-col.ant-form-item-control .ant-form-item-control-input {
      flex: 1;
      .ant-form-item-control-input-content {
        height: 100%;
      }
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}

.fitRangePanelForFixCalendar {
  width: 240px;
  height: 100%;
  background-color: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.calendarDisplayPanel {
  flex: 1;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  position: relative;
}
