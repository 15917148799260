.overallContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.fullScreenButton {
  position: absolute;
  z-index: 10;
  right: 0px;
  cursor: pointer;
  margin: 10px;
}
.reportIframeDiv {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s;
  background-color: white; //   帆软加载的报表背景为透明色，全屏显示下透明色为黑色，因此背景颜色设为白色避免看不到内容
}
.report {
  width: 100%;
  height: 100%;
}
