.hintContainer {
  width: 200px;
}
.legendsContainer {
  padding: 15px;
  border-top: 1px solid #787878;
  margin-top: 10px;

  .legendInfo {
    display: flex;
    align-items: center;
    .sampleLine {
      display: inline-block;
      width: 60px;
      height: 1px;
      background-size: 10px 1px;
      background-repeat: repeat-x;
      opacity: 0.8;
      margin-left: 10px;
    }
    .legendName {
      width: 50px;
    }
  }
}
