.editableTextComp {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
}
.editableText {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.editableWitdh {
  max-width: calc(100% - 32px);
}
.pureTextWidth {
  max-width: 100%;
}
