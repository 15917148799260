.tagContent {
  min-height: 32px;
  padding: 3px 5px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.disabled {
  background-color: #f5f5f5;
  color: rgba($color: #000, $alpha: 0.25);
  cursor: not-allowed;
}
