.tree {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 0;
  padding: 10px;
  :global {
    .ant-tree,
    .ant-tree .ant-tree-list-holder {
      height: 100%;
    }
    .ant-tree-list {
      height: 100%;
      background-color: #fafafa;
    }
  }
}

.treeOverflow {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 0;
  padding: 10px;
  :global {
    .ant-tree,
    .ant-tree .ant-tree-list-holder {
      height: 100%;
      overflow: auto;
    }
    .ant-tree-list {
      height: 100%;
      background-color: #fafafa;
    }
  }
}

.treeComponent {
  margin-top: 10px;
  margin-right: -10px;
  overflow: hidden;
  flex-grow: 1;
  background-color: #fafafa;
  position: relative;
}

.topRender {
  margin-top: 10px;
}

.bottomRender {
  margin-top: 10px;
}

.expandBtn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  margin-right: 18px;

  color: #02b980;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  width: 45px;
}
