.moduleTopInfo {
  margin-bottom: 12px;
}

.searchContent {
  width: 272px !important;
  margin-bottom: 20px;
}

.formulaInput {
  margin-right: 8px !important;
}

.topButton {
  position: absolute !important;
  right: 20px;
  top: 10px;
  z-index: 9;
}

.tableFormItem {
  margin: 'auto';
}

.editMaterilButon {
  position: absolute !important;
  top: 0;
}
