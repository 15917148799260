@import 'src/styles/color/variable.scss';

.draggableTree {
  padding-left: 10px !important;
  :global {
    .ant-tree-treenode {
      position: relative;
      width: 100%;
      .ant-tree-switcher {
        padding-top: 6px;
      }
    }
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: $menuGreen !important;
      .treeNodeTitletext {
        font-weight: bold;
      }
    }
    .ant-tree-title {
      width: 100%;
    }
    .ant-tree-node-content-wrapper {
      display: flex;
      flex: 1;

      // &:hover :global {
      //   .iconNode {
      //     display: inline-flex;
      //   }
      // }
    }
    .ant-tree-indent-unit::before {
      display: none;
    }
    .ant-tree-switcher-leaf-line {
      &::before,
      &::after {
        display: none;
      }
    }
    .customIcon {
      position: relative;
      width: 17px;
      height: 17px;
      padding: 0;
      margin-top: 4px;
      border: 1px solid #f0f0f0;
      content: '';
      &::before,
      &::after {
        position: absolute;
        background: currentColor;
        transition: transform 0.3s ease-out;
        content: '';
      }
      &::before {
        transform: rotate(-180deg);
        top: 7px;
        right: 3px;
        left: 3px;
        height: 1px;
      }
      &::after {
        transform: rotate(0deg);
        top: 3px;
        bottom: 3px;
        left: 7px;
        width: 1px;
      }
    }
    .ant-tree-switcher {
      &:hover {
        .customIcon {
          border: 1px solid #22c78d;
          color: #22c78d;
        }
      }
    }
    .ant-tree-switcher_open {
      .customIcon {
        &::after {
          display: none;
        }
        &::before {
          transform: rotate(0);
        }
      }
    }
  }
  .titleWrap {
    padding: 6px 0;
  }

  :global {
    .iconNode {
      // display: inline-flex;
      margin-left: 4px;
      vertical-align: middle;
    }

    .iconDrag {
      position: absolute;
      left: -36px;
      top: 6px;
    }
    .ant-tag {
      height: 22px;
      width: 28px;
      text-align: center;
      margin-right: 4px;
    }
    .titleCon {
      &:hover :global {
        .iconNode {
          display: inline-flex;
        }
      }
    }
  }
}
