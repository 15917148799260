.other-node,
.condition-node {
  width: 340px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #d9d9d9;
}

.other-node {
  display: flex;
  flex-direction: column;
}

.condition-node {
  height: 54px;
  padding: 6px 16px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.condition-content {
  flex: 1;
}

.node-status-configured-title,
.node-status-un-configured-title {
  width: 100%;
  height: 34px;
  font-size: 14px;
  padding: 6px 16px;
  background-color: #f5f5f5;
}

.node-status-configured-title {
  color: #262626;
}
.node-status-un-configured-title {
  color: #8c8c8c;
}

.no-configured-condition {
  background-color: #f5f5f5;
  color: #8c8c8c;
}

.title {
  position: relative;
}

.delIcon {
  position: absolute;
  right: -9px;
  top: -10px;
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  display: none;
}
.other-node:hover {
  border: 1px solid #02b980;
}
.other-node:hover .title {
  background-color: #02b980;
}

.other-node:hover .delIcon {
  display: block;
}

.other-node:hover .title {
  color: #fff;
}

.node-status-right {
  color: #8c8c8c;
}
.other-node:hover .node-status-right {
  color: #02b980;
}

.condition-node:hover .delIcon {
  display: block;
}

.node-status-configured-content {
  padding: 16px;
  display: flex;
}

.node-status-un-configured-content {
  padding: 16px;
  color: #bfbfbf;
  display: flex;
}

.show-way {
  width: 40px;
  height: 20px;
  line-height: 20px;
  margin-right: 4px;
  font-size: 12px;
  text-align: left;
  padding-left: 8px;
}

.show-tag {
  flex: 1;
  height: 100%;
}
