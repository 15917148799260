.privacyModal {
  height: 800px;
  :global {
    .ant-modal-footer {
      text-align: center;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-title {
      text-align: center;
    }
  }
}

.userPrivacyIframe {
  width: 100%;
  height: 650px;
}
