.integratePickConfigContainer {
  :global {
    #dataFormInfo .ant-row {
      .ant-col.ant-col-24:first-child {
        label {
          padding: 0;
        }
      }
    }
  }
}
