.roleSelectModalbody {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.roleSelectModalfooter {
  width: 100%;
  display: flex;
  justify-content: center;
}
