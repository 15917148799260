.shiftTable {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-table-footer {
      border: 1px dashed #d9d9d9;
      background-color: #fff;
      margin-top: 8px;
    }
    .timeBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .time {
        flex: 1;
      }
      .nextDayTagBox {
        width: 60px;
        height: 20px;
        .nextDayTag {
          margin-left: 10px;
          width: 40px;
          height: 20px;
          line-height: 19px;
          text-align: center;
          background-color: #eefaf7;
          color: #02b980;
          border: 1px solid #02b980;
        }
      }
    }
  }
}

.colorBox {
  :global {
    .colorSpace {
      margin-right: 10px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.radioButton {
  :global {
    .ant-radio-button-checked .ant-radio-button-inner::after {
      position: absolute;
      top: 13px;
      left: 8px;
      display: table;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
      width: 8px;
      height: 15px;
    }
  }
}
