/*  */

.chars_select .ant-select-selection-overflow {
  justify-content: flex-start;
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.chars_select .ant-select-selection-overflow::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
