$borderColorBase: rgba(0, 0, 0, 0.15);

#save-back-btn {
  color: #02b980;
  border-color: #02b980;

  &:hover {
    color: #22c78d;
    border-color: #22c78d;
  }
}

#layout-editor {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: calc(100vh - 180px);
  min-height: 200px;
  max-height: 722px;
  border: 1px solid $borderColorBase;
  overflow-x: auto;
}
.separator {
  height: 100%;
  flex: 0 0 1px;
  background: $borderColorBase;
}
.card {
  line-height: 1.5;
}
.card-header {
  background-color: #fafafa;
  padding: 10px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.card-extra {
  color: rgba(0, 0, 0, 0.25);
}
.card-main {
  height: calc(100% - 44px);
  overflow: hidden auto;
}

.source-list {
  .source-list-part {
    padding: 16px 16px 0;
  }

  .source-list-item {
    margin-bottom: 16px;
    border: 1px solid #d9d9d9;
    cursor: grab;
    user-select: none;

    :global {
      .ant-card-body {
        padding: 0 12px;
        line-height: 32px;
      }
    }
  }

  .source-list-item-disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.15);
  }

  .source-list-separator {
    height: 10px;
    background-color: #fafafa;
  }
}

.layout-editor-page-info {
  display: flex;
  flex-direction: column;
}
.component-list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.component-list {
  flex: 0;
  position: relative;

  .component-list-item-wrapper {
    padding: 0 !important;
    border-bottom: 1px solid #d9d9d9;
    user-select: none;

    &.drop-over-downward {
      border-bottom: 2px dashed #22c78d !important;
    }
    &.drop-over-upward {
      border-top: 2px dashed #22c78d !important;
    }
  }
}
.component-list-item {
  padding: 4px;
  width: 100%;
  display: flex;
  align-items: flex-start;

  > span,
  > div {
    min-height: 46px;
  }

  > span {
    display: flex;
    align-items: center;
  }

  > div {
    line-height: 46px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.component-list-item-title-input {
  margin-left: 12px !important;
  flex: 1 1 440px;
  min-width: 260px;
}
.component-list-item-title {
  margin-left: 24px;
  flex: 1 1 418px;
  min-width: 260px;
  position: relative;
}
.component-list-item-title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.component-list-item-title-tooltip-body {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.component-list-item-info-area {
  flex: 0 1 260px;
  min-width: 130px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.component-list-item-info-area-long {
  @extend .component-list-item-info-area;
  padding-left: 24px;
  flex-basis: 520px;
  min-width: 320px;
}
.component-list-item-flexible-gap {
  min-width: 24px;
  max-width: 72px;
  flex: 1;
}

.missing-required-fields-modal {
  :global {
    .ant-modal-confirm-btns {
      padding-left: 22px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
