.fitRangeTitle {
  height: 54px;
  padding: 15px 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.fitRangeTabs {
  flex: 1;
  :global {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }
    .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
      letter-spacing: 4px;
      padding-left: 3px;
    }
    .ant-tabs-content.ant-tabs-content-top {
      height: 100%;
    }
  }
  .fitRangeTabPane {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
