.fieldList {
  height: 100%;
  .ant-tabs-nav {
    background-color: #fff;
    padding-left: 28px;
    border-bottom: solid #eee 1px;
    border-top: solid #eee 1px;
    margin-bottom: 0 !important;
  }
  .ant-tabs {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
    }
  }
}

.ant-cascader-menu {
  width: 200px;
}
