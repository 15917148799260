.parcel {
  height: 100%;
  padding: 8px;
  background-color: #fff;
  .tabs {
    height: 100%;
    :global {
      .ant-tabs-tab {
        width: 208px;
        height: 40px;
        margin: 4px 0 0 0 !important;
      }
      .ant-tabs-tab-active {
        background-color: #ddf9eb;
      }
      .ant-tabs-content {
        height: 100%;
      }
    }
  }
}

.authModal {
  :global {
    .ant-modal-footer {
      text-align: center;
    }
  }
}

.box {
  margin-right: 24px;
  height: 100%;
}

.content {
  height: calc(100vh - 168px);
}

.password {
  background-color: #fffbe6;
  color: #faad14;
  height: 48px;
  display: block;
  padding: 12px 0 12px 24px;
  position: relative;
}

.bindPhone {
  margin: 24px;
  .changePhoneBox {
    margin: 0 auto;
    width: 480px;
  }
  .changePhoneBtn {
    width: 480px;
    height: 32px;
    margin-top: 24px;
  }
}

.bindingPhone {
  height: 32px;
  margin: auto;
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  .changePhone {
    cursor: pointer;
    height: '100%';
    width: 80px;
    border: 1px solid #02b980;
    color: #02b980;
    text-align: center;
    border-radius: 2px;
    margin-left: 24px;
    font-size: 14px;
  }
  .phoneNumber {
    font-size: 20px;
    color: #262626;
    font-weight: 600;
    margin-left: 8px;
  }
}

.authTitle {
  height: 22px;
  color: #8c8c8c;
  margin-bottom: 16px;
}

.authMessage {
  display: flex;
  width: '100%';
  .message {
    width: 328px;
    height: 32px;
    border: 1px solid #d9d9d9;
    color: #bfbfbf;
  }
  .auth {
    width: 110px;
    height: 32px;
    color: #02b980;
    line-height: 32px;
    margin-left: 8px;
    border: 1px solid #02b980;
    text-align: center;
  }
}

.bindHeader {
  height: 56px;
  :global {
    .ant-page-header-heading {
      height: 56px;
      padding: 16px 24px;
      background-color: #fafafa;
    }
    .ant-page-header-heading-left {
      height: 24px;
      padding-left: 8px;
      border-left: 2px solid #02b980;
      margin: 0;
    }
    .ant-page-header-heading-title {
      font-size: 16px;
    }
  }
}

.messageAuthTitle {
  height: 22px;
  font-size: 14px;
  text-align: left;
  margin-bottom: 16px;
  .messageTitle {
    color: #8c8c8c;
  }
  .bindingPhone {
    color: #262626;
    font-weight: 600;
    display: inline;
  }
}

.codeBox {
  width: 480px;
  height: 32px;
  display: flex;
  .codeButton {
    width: 130px;
    height: 32px;
    border: 1px solid #02b980 !important;
    color: #02b980 !important;
    border-radius: 2px !important;
    margin-left: 8px;
    text-align: center;
  }
}

.smsCodeBase {
  width: 480px;
  margin: 0 auto;
}

.code {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input {
    width: 340px;
    height: 32px;
  }

  :global .ant-input-number-input {
    height: 32px;
  }
}

.imgWrap {
  position: relative;
  width: 130px;
  height: 32px;

  &:hover {
    .handler {
      display: flex;
    }
  }

  .handler {
    display: none;
    width: 130px;
    height: 32px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
    color: #ffffff;
    cursor: pointer;
  }
}
