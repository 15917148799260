.inner {
  padding: 20px;
}
.standard {
  margin-bottom: 20px;
}
.box {
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: 50%;
    margin: 20px 0;
  }
}
