.areaHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.areaFooter {
  display: flex;
  justify-content: space-between;
}

.treeDetailContent {
  height: 100%;
  :global .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
}
