@import '@blacklake-web/component/dist/index.esm.css';
@import '@blacklake-web/layout/dist/index.esm.css';

body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

th .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px !important;
  height: 100% !important;
  cursor: col-resize;
  background-size: 0;
}

.ant-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.bl-modal .ant-modal-footer {
  text-align: center;
}
