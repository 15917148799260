@import 'src/styles/color/variable.scss';

.bl-list-select {
  border: 1px solid $borderColor;
  width: 240px;
}
.bl-list-select-title {
  background-color: $listHeader;
  height: 48px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
}
.bl-list-select-list {
  height: 323px;
  overflow: hidden auto;
  background-color: $lightBackground;

  .bl-list-select-list-item {
    height: 48px;
    padding: 12px 24px !important;
    cursor: pointer;
  }
  .bl-list-select-list-item:hover,
  .bl-list-select-list-item-active {
    background-color: #fff;
    color: $blacklakeGreen;
  }
}
.bl-list-select-search-wrapper {
  height: 48px;
  padding: 7px 24px;
  background-color: $lightBackground;
}
