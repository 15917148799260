@import 'src/styles/color/variable.scss';

.submenu {
  background: #fff;
  height: 100%;

  .title {
    padding-left: 30px;
    box-sizing: content-box !important;
    height: 64px;
    font-size: 16px;
    line-height: 64px;
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 0px;
  }

  :global {
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-header {
      padding-right: 0;
    }
  }

  .menuItem {
    display: block;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    padding-right: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .menuItemSelected {
    background: #e1faee;
    border-right: 2px solid #02b980;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  .menuItem:hover {
    background: #f0f0f0;
  }

  .menu-collapse-item {
    :global {
      .ant-collapse-header {
        margin-right: 12px;
        font-size: 16px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow-x: hidden;
      }
    }
  }
}

#root-menu {
  width: 124px;

  .root-menu-item {
    font-size: 16px;
    color: $white65;
    margin: 0;
    height: 48px;
    line-height: 48px;
    display: flex;
    align-items: center;
    padding-left: 24px;
  }

  :global {
    .ant-menu-item-selected {
      color: $black65;
    }
  }
}
