$border-color: #d9d9d9;

.detailTab {
  margin: 24px;
  border-left: solid 1px $border-color;
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid $border-color;
    border-left-width: 0;
  }
  .ant-tabs-content-holder {
    min-height: 200px;
  }
  .ant-tabs-content {
    margin-top: -16px;
  }
  .ant-tabs-content > .ant-tabs-tabpane {
    border-right: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
  }
  .ant-tabs-content > .ant-tabs-tabpane > div {
    padding-bottom: 0 !important;
  }

  .ant-descriptions-item-content {
    width: 100%;
    .blTable {
      width: 100%;
    }
  }
}

.detailContentTab {
  width: 100%;
  .ant-tabs-content {
    height: 200px;
    margin-top: -16px;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid $border-color;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fafafa;
  }
  .detailContentTab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .detailContentTab .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: #ffffff;
  }
  .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fafafa;
    border-right: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
    border-left: solid 1px $border-color;
  }
}

.avatarImage {
  margin-left: 20px;
  display: flex;
  align-items: flex-end;
  width: 45%;
  justify-content: space-between;
}
