$blacklakeGreen: #02b980;

.flexCalendarTableContainer {
  height: 652px;
  display: flex;
  flex-direction: column;
}

.flexCalendarTableHeader {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.flexCalendarTable {
  flex: 1;
  :global {
    .ant-table.ant-table-bordered > .ant-table-container {
      overflow-x: auto;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-top: 0;
      height: 100%;

      .ant-table-body {
        height: 536px;
      }

      .ant-table-thead > tr > th:not(:first-child),
      .ant-table-tbody > tr > td:not(:first-child),
      > .ant-table-content > table > thead > tr > th:not(:first-child) {
        padding: 0;
        text-align: center;
        hr {
          background-color: rgba(0, 0, 0, 0.15);
          border: none;
          height: 1px;
          margin: 2px 0;
        }
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td,
      > .ant-table-content > table > thead > tr > th,
      > .ant-table-content > table > tbody > tr > td {
        border-color: rgba(0, 0, 0, 0.15);
        &:not(:first-child) {
          padding: 0;
        }
      }

      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: white;
      }

      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}

.fitRangePanelForFlexCalendar {
  width: 200px;
  background-color: #fafafa;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.calendarDisplayPanel {
  flex: 1;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pannelTitle {
  height: 54px;
  padding: 15px 13px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  .pannelTitleText {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.userCell {
  display: flex;
  justify-content: space-between;
  :global {
    .anticon {
      padding-top: 4px;
    }
  }
}

.scheduleCell {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  &:hover {
    cursor: pointer;
    border: 2px solid $blacklakeGreen;
    padding: 0 3px;
  }
  &:not(:only-child) {
    cursor: pointer;
    border: 2px solid $blacklakeGreen;
    padding: 0 3px;
  }
  &.disabledCell {
    background-color: #f0f0f0;
    cursor: not-allowed;
    &:hover,
    &:focus {
      border: none;
      cursor: not-allowed;
    }
  }
  .shiftName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
