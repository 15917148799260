.control-component {
  width: 100%;
  min-height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.control-comp-wrapper {
  flex-grow: 1;

  & > .ant-form-item {
    margin-bottom: 0;
    flex-direction: row !important;

    & > .ant-form-item-label {
      text-align: right !important;
      padding: 0 !important;
      min-height: 32px !important;
      white-space: normal !important;
      overflow: visible !important;
    }

    .ant-form-item-extra {
      word-break: break-word;
    }
  }
}
.ant-form-vertical .control-comp-wrapper .ant-form-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.control-action {
  visibility: hidden;
  pointer-events: none;
}

@mixin drop-bottom-dash {
  border-bottom: 2px dashed #22c78d !important;
}
@mixin drop-top-dash {
  border-top: 2px dashed #22c78d;
}

.control-list-item {
  border-bottom: none !important;
  padding: 0 !important;

  &.drop-over-downward {
    @include drop-bottom-dash();
  }

  &.drop-over-upward {
    @include drop-top-dash();
  }
}

.step-list-table {
  table {
    border-collapse: collapse !important;
  }

  .drop-over-downward {
    @include drop-bottom-dash();
  }
  .drop-over-upward {
    @include drop-top-dash();
  }
  .ant-table-empty {
    .anticon {
      display: none;
    }
  }
}

.control-list-item-selected {
  background-color: #eee;
}

.control-list-item:hover .control-action {
  visibility: visible;
  pointer-events: all;
}

.hover-icon {
  cursor: pointer;
}
.hover-icon:hover {
  opacity: 0.5;
}

// 强迫数字控件的<InputNumber>展示增减按钮
.control-numeric .ant-input-number-handler-wrap {
  opacity: 1;
}

.text-format-previewer {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #f5f5f5;
  color: #02b980;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-list-step-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.control-cascader {
  input {
    pointer-events: none;
  }
}
.control-editor-form {
  .ant-form-item-control {
    flex-basis: 0% !important;
  }
}
