.qrCodeModal {
  :global {
    .ant-modal-footer {
      text-align: center;
    }
    .qrCodeCreateBox {
      .qrCodeCreateTitle {
        display: flex;
        .count {
          font-weight: 700;
        }
      }
    }
  }
}
