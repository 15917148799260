.BlSteps {
  :global {
    .ant-steps-item-icon {
      width: 18px !important;
      height: 18px !important;
      font-size: 10px !important;
      margin-top: 4px !important;
    }
    .ant-steps-item-icon .ant-steps-icon {
      top: -4.5px !important;
    }
    .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
      width: 18px !important;
      height: 18px !important;
      font-size: 18px !important;
    }
    .ant-steps-item-tail {
      top: -4px !important;
      left: 8px !important;
    }
    .ant-steps-item-title {
      color: #333333 !important;
    }
    .ant-steps-item-subtitle {
      border-style: none !important;
    }
    .ant-tag {
      border-style: none !important;
    }
  }
}
