.modal {
  :global {
    .ant-modal-body {
      // padding: 24px 32px 24px !important;
    }

    .ant-modal-confirm-body {
    }

    .ant-modal-confirm-btns {
      float: none;
      display: flex;
      justify-content: center;
      margin-top: 0 !important;
    }
  }
}

.hideFooter {
  :global {
    .ant-modal-confirm-body {
    }

    .ant-modal-confirm-btns {
      display: none;
      margin-top: 0 !important;
    }
  }
}

.headerStyle {
  position: relative;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 56px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(217, 217, 217, 0.35);
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 56px;
  height: 56px;
  cursor: pointer;

  pointer-events: auto;
}
