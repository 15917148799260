.conditionContainer {
  flex: 1;
  display: flex;
  width: 100%;
  padding: 24px 16px 0px;
  border-bottom: 1px solid #eaecf1;
  :global {
    // .ant-row.ant-form-item {
    //   width: calc(50% - 20px);
    //   margin-right: 15px;
    // }

    .ant-form-inline {
      .ant-form-item {
        width: 31%;
        margin-bottom: 20px;
      }

      .ant-form-item:nth-child(3) {
        margin-right: 0px;
      }
    }
  }
}

.hintInfo {
  height: 40px;
  line-height: 0px;
  width: 50%;
  position: relative;
  z-index: 1;
  background: white;
  padding: 32px 16px;
  margin-bottom: -63px;

  .errorHint {
    color: #ff4d4f;
  }

  .maxHint {
    color: #595959;
  }
}
