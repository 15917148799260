.autoFrequencyContainer {
  width: 100%;
  align-items: start !important;
  :global {
    .ant-space-item {
      display: contents;
    }
    .ant-row.ant-form-item {
      margin: 0;
    }
  }
  .autoFrequencyInput {
    flex: 1;
  }
  .autoFrequencySelect {
    width: 100px;
  }
}
