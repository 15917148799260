.selectBody {
  display: flex;
  width: 100%;
  height: 450px;

  .list {
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;

    .listItem {
      padding: 0px 20px;
    }

    .selectedListItem {
      background-color: rgba(2, 185, 128, 0.2);
    }
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
}
