.useAgeFilterContainer {
  width: 100%;
  :global {
    .ant-space-item {
      display: contents;
    }
    .ant-row.ant-form-item {
      margin: 0;
    }
  }
  .useAgeInput {
    flex: 1;
  }
  .useAgeTypeSelect {
    width: 100px;
  }
}
