@import 'src/styles/color/variable.scss';

$NODE_WIDTH: 147px;
$nodeMinHeight: 47px;
$primary: #02b980;

.nodeContainer {
  display: inline-block;
  width: $NODE_WIDTH;
  height: $nodeMinHeight;
  line-height: $nodeMinHeight;
  border: 1px solid #d9d9d9;
  position: relative;
  z-index: 1;
  cursor: move;
  border-radius: 2px;
  background: #fafafa;
  vertical-align: middle;
  text-align: center;
}

.nodeInner {
  width: 90%;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editingNodeContainer {
  background: #fff;
  color: $primary;
  border: 1px solid $primary;
  box-shadow: $primary 0px 0px 6px;
}

.badge {
  position: absolute;
  height: 23px;
  width: 28px;
  line-height: 23px;
  border-radius: 50%;
  left: -12px;
  top: -7px;
  color: #fff;
  font-size: 12px;
  background-color: #009dff;
}

.spot {
  font-size: 16px;
  color: #02b980 !important;
  background: #fff;
  padding-right: 0;
  border-radius: 50%;
  position: absolute;
  right: -7px;
  margin-top: -7px;
  top: 50%;
  cursor: pointer;
}

.deleteIcon {
  font-size: 16px;
  position: absolute;
  top: 0%;
  background: #fff;
  color: #ccc !important;
  margin-top: -7px;
  right: -7px;
  cursor: pointer;
}

.line {
  display: inline-block;
  width: 40px;
  height: 8px;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    border-top: 2px solid #d9d9d9;
  }
}
.editingLine {
  &:after {
    border-color: #02b980;
    box-shadow: 0 0 5px 1px #02b980;
  }
}
