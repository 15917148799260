@import 'src/styles/color/variable.scss';

.object-list {
  height: 440px;
  overflow-y: auto;

  .object-list-item {
    height: 48px;

    .object-check-box {
      width: 100%;
      overflow: hidden;
    }

    :global {
      .ant-checkbox + span {
        padding-left: 24px;
        overflow: hidden;
      }
    }
  }
  .object-list-left-item {
    padding-left: 24px !important;
    border-bottom: 1px solid $borderColor !important;
  }
  .object-list-right-item {
    padding-left: 4px !important;
  }
  .object-list-right-item-checkbox {
    padding-left: 4px;
  }
}

.sort-modal-item-title {
  margin-left: 24px;
}
