.object-settings {
  height: 100%;
  display: flex;
  align-items: stretch;
  background: #fff;
}

.object-settings-left {
  flex: 0 0 144px;
  border-right: 1px solid #f0f0f0;

  :global {
    .ant-menu {
      border-right: none;

      .ant-menu-item {
        margin: 0 !important;
      }

      .ant-menu-item-selected > .ant-menu-title-content {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .ant-menu-inline .ant-menu-item::after {
      border-right-width: 2px !important;
    }
  }
}

.title-wrapper {
  height: 64px;
  padding: 20px 24px;
  border-bottom: 1px solid #f0f0f0;
}

.object-settings-right {
  flex: 1;
  width: 0;

  :global {
    .ant-tabs-nav {
      padding: 0 24px !important;
    }
    .ant-tabs-tab-active {
      font-weight: 500 !important;
    }
  }
}
