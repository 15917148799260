.addMaterialModal {
  :global {
    .ant-modal-footer {
      text-align: center;
    }
    .ant-modal-body {
      padding: 0;
      height: 710px;
    }
  }
}
