.customerCode {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-table-footer {
      padding: 8px 16px;
      background-color: #fff;
      .addIcon {
        box-sizing: border-box;
        text-align: center;
        flex: 1;
        cursor: pointer;
        background-color: #fff;
        margin: 8px 0px 8px 0px;
        &:first-child {
          margin-right: 16px;
        }
        .ant-btn {
          width: 100%;
          &:hover {
            color: #22c78d;
          }
        }
      }
    }
  }
}
