.toggleStyle {
  width: 40px;
  height: 20px;
  background: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10px;
  border: 1px solid #b1b1b12e;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: #02b980;
    color: #02b980;
  }
}
