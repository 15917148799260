@media print {
  .printHide {
    display: none;
  }

  .printContainer {
    display: block;
  }
}
.tableLayout {
  .unitLayout {
    margin: 16px;
  }
  :global {
    .ant-pro-basicLayout {
      height: 100%;
    }

    .ant-layout {
      background: #f0f6f4;
    }

    #customize_menu_header {
      display: flex;
      justify-content: space-between;
      margin: 16px;
      align-items: center;
      & > img {
        width: 134px;
        // height: 32px;
      }
    }

    .ant-layout-header {
      background: #fff;
    }

    .search {
      margin: 16px 24px 8px 24px;
    }
  }
}
