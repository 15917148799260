.innerFormItem {
  margin-bottom: 0;
  .ant-form-item {
    margin-bottom: 0;
  }
}

.innerFormItemGlobal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.spaceTop {
  margin-top: 24px;
}

.or {
  margin: 20px;
}
