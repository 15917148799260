.pdf-view-wrapper {
  background-color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 900px;
  position: relative;
  // overflow: scroll;
  // padding: 50px;
}

.pdf-view-actions {
  z-index: 100;
  position: absolute;
  bottom: 0;
  background-color: rgba(37, 37, 37, 0.7);
  width: 300px;
  display: flex;
  font-size: 18px;
  user-select: none;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  padding: 5px 3px;

  svg {
    color: #fff;
  }
  // div {
  //   font-size: 18px;
  //   display: inline-block;
  //   padding: 5px 3px;
  //   svg {
  //     color: #fff;
  //   }
  // }
}
