@import 'src/styles/color/variable';
$fontColor: #626262;

.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid $borderColor;

    .text {
      font-size: 20px;
      color: $fontColor;
    }
  }

  .content {
    flex: 1;
    padding: 24px;
    overflow-y: auto;

    .cardContainer {
      background-color: $black02;
      border-radius: 8px;

      .cardHeader {
        display: flex;
        align-items: center;
        height: 56px;
        padding-left: 24px;
        background-color: rgba(0, 0, 0, 0.02);

        .cardHeaderText {
          font-size: 16px;
          font-weight: 500;
          color: $fontColor;
        }
      }
    }

    .temText {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border-top: 1px solid $borderColor;
  }
}

.helpPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.helpTipList {
  padding-left: 16px;
}

.helpIcon {
  margin-left: 5px;
  &:hover :global {
    svg {
      fill: $blacklakeGreen;
      color: $blacklakeGreen;
    }
  }
}
