.detailContent {
  position: relative;
}
.collapse {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 48px;
  cursor: pointer;
  background-color: #f4f3f4;
}
.caretLeft {
  position: absolute;
  top: 14px;
  right: 0px;
}
