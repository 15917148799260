@import 'src/styles/color/variable.scss';

.name {
  padding: 10px;
  margin-top: 20px;
}

.backendMenu-module {
  color: #8c8c8c;
  line-height: 40px;
  display: block;
  height: 40px;
  padding-left: 24px;
  font-size: 14px;
}

.backend-menu {
  :global {
    .ant-menu-submenu,
    .ant-menu-title-content {
      font-size: 16px;
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title {
      color: #02b980 !important;
    }
  }
}

.backend-first-level-menu-item {
  color: rgba(0, 0, 0, 0.85) !important;

  & > span > a {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}
