.addField {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .addFieldItem {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .addText {
    margin-right: 10px;
  }
  .addFieldDiv {
    width: 100%;
    height: 100%;
    &:focus {
      outline: none;
    }
    padding: 3px 11px;
    border: 1px solid #d9d9d9;
  }
}
.addUser {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
