.tabs {
  height: 100%;
  background-color: white;

  :global {
    .ant-tabs-nav {
      margin-bottom: 0px !important;
      padding: 0px 16px;
    }

    .ant-tabs-content {
      height: 100%;
    }
  }
}
