.react-resizable {
  position: relative;
}
.react-resizable-handle {
  height: 100%;
  width: 1px;
  position: absolute;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  right: 0;
  top: 0;
  cursor: col-resize;
}
