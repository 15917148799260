.logo-upload-bg {
  width: 440px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../assets/upload-image-bg.png');
  background-position: left top;
}

.logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;

  .logo-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 260px;
    max-height: 168px;
    object-fit: contain;
    object-position: center;
  }

  .logo-delet-btn {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &:hover > .logo-delet-btn {
    display: block;
  }
}

.menu-head-preview {
  width: 110px;
  height: 110px;
  background-image: url('../../../assets/upload-image-bg.png');
  background-position: left top;
  color: '#bebebe';
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-head-preview-logo {
  max-width: 76px;
  max-height: 48px;
  object-fit: contain;
  margin-bottom: 8px;
}
