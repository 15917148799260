.areaFooter {
  display: flex;
  justify-content: space-between;
}

.noLocationSelected {
  background-color: #9aedc8;
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
}

.treeDetailContent {
  height: 100%;
  :global .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
}
