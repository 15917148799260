.userTooltipContent {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

.tagNoWrap {
  // nowrap 在详情页三列时会出现样式错乱, 姑且用 pre-wrap
  white-space: pre-wrap;
}

.tagTooltip {
  :global {
    .ant-tag {
      margin-top: 2px !important;
      margin-bottom: 2px !important;
    }
  }
}
