@import 'src/styles/color/variable.scss';

.blMenu {
  display: flex;
  flex-direction: column;
  height: 100%;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #868686;
    border-radius: 4px;
  }

  .header {
    padding: 20px 0px;
    text-align: center;

    .name {
      color: $white65;
      font-size: 16px;
      margin-top: 16px;
      font-weight: 700;
    }
  }

  .backHeader {
    padding: 20px 0px;
    text-align: center;

    .name {
      font-size: 16px;
      margin-top: 16px;
      font-weight: 700;
    }
  }

  .body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    width: 100%;
    padding: 20px 0px;
    cursor: pointer;
  }
}

.backendFooter {
  width: 100%;
  position: absolute;
  bottom: 24px;
  cursor: pointer;
  :global {
    .ant-menu-item {
      text-align: center;
    }
  }
}

.menu_sider {
  background-color: $menuBlack !important;
}
