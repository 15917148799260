.innerFormItem {
  .ant-form-item {
    margin-bottom: 0;
  }
}
.innerFormItemGlobal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.innerFormItemControlInputGlobal {
  :global {
    .ant-form-item-control-input {
      min-height: 0;
    }
  }
}
.or {
  margin: 20px;
}
.addItemButton {
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-top: 10px;
}
.editTable {
  // box-sizing: border-box;
  // padding: 0px 20px;
  // width: 100%;
  :global {
    .ant-table-tbody .ant-table-row .ant-table-cell {
      padding-bottom: 0;
    }
  }
}
