@import 'src/styles/color/variable.scss';

.bl-light-transfer-container {
  display: flex;
}

.bl-light-transfer-list {
  width: 240px;
  border: 1px solid $borderColor;

  > .ant-list {
    height: 323px;
    overflow-y: auto;
  }

  .bl-light-transfer-list-title {
    background-color: $listHeader;
    height: 48px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
  }
  .bl-light-transfer-list-search-wrapper {
    height: 48px;
    padding: 7px 24px;
  }
  .bl-light-transfer-list-extra {
    height: 48px;
    padding: 12px 24px;
    font-size: 13px;
    color: $black45;
  }

  .bl-light-transfer-list-item {
    height: 48px;
    padding: 12px 24px !important;
    user-select: none;
  }
}

.bl-light-transfer-options .bl-light-transfer-list-item {
  cursor: pointer;
}
.bl-light-transfer-selected {
  border-left: none;

  .bl-light-transfer-list-item:hover {
    background-color: #fff;
  }
}
