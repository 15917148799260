.filterContent {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  display: flex;
  border: 1px solid #d4d5d5;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  .filterType {
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .filterLine {
      background-color: #d4d5d5;
      width: 1px;
      height: 50%;
      margin: 0;
      padding: 0;
    }
  }

  .filterRightContent {
    flex: 1;
    border: none;
    padding: 10px 10px 0 10px;
    height: 100%;
  }
}

.filterCondition {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  display: flex;
  justify-content: space-around;
  .filterSelect {
    flex: 1;
    width: 200px;
    margin: 5px;
  }
  .operation {
    width: 100px;
    margin-left: 10px;
    height: 56px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .operationItem {
      flex: 1;
      cursor: pointer;
    }
  }
}

.cptRelatedParam {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .cptRelatedParamItem {
    flex: 1;
    width: 100px;
    margin: 5px;
  }
  .cptRelatedParamDelete {
    width: 22px;
    margin-left: 5px;
    .cptRelatedParamDeleteIcon {
      color: #ff4d4f;
      font-size: 22px;
    }
  }
}

.templateDataFieldTableName {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.templateTable {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-table-footer {
      padding: 8px 0px;
      background-color: #fff;

      .add {
        box-sizing: border-box;
        text-align: center;
        flex: 1;
        cursor: pointer;
        border: 1px dashed #d9d9d9;
        background-color: #fff;
        margin: 8px 0px 8px 0px;
        padding: 5px 0;
        color: rgba(0, 0, 0, 0.65);
      }
      .add:hover {
        color: #22c78d;
        border-color: #22c78d;
      }
    }
  }
}

.templateModal {
  :global {
    .ant-modal-footer {
      text-align: center;
    }
  }
}

.previewParams {
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}
