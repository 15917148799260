.tabsBox {
  height: 100%;
  background-color: #fff;
  padding: 16px 24px;
  .tabs {
    height: 100%;
    :global {
      .ant-tabs-tab {
        width: 88px;
        height: 40px;
        padding: 9px 16px;
        background-color: #fafafa;
        border-top: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
        border-bottom: none;
        margin: 0 2px;
        border-radius: 2px 2px 0px 0px;
      }
      .ant-tabs-tab-active {
        background-color: #fff;
      }
      .ant-tabs-nav {
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 0;
      }

      .ant-tabs-content {
        height: 100%;
      }
    }
  }
}
.tabsBoxPopover {
  padding: 0px 8px 8px 8px;
  background-color: #fff;
  .tabs {
    height: 100%;
    :global {
      .ant-tabs-tab {
        height: 34px;
        width: 56px;
        margin: 0 17px;
        &:first-child {
          margin-left: 0px;
        }
      }
      .ant-tabs-tab-active {
        background-color: #fff;
      }
      .ant-tabs-nav {
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 0px;
      }
    }
  }
}

.PopoverHeader {
  width: 384px;
  height: 22px;
  margin: 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .PopoverHeaderUnread {
    display: flex;
    height: 100%;
    .num {
      padding: 0px 10px;
      border-radius: 11px;
      border: 1px solid #eeeeee;
      margin-left: 5px;
      background-color: #eeeeee;
    }
  }
  .PopoverHeaderRead {
    height: 100%;
    cursor: pointer;
  }
}
.notificationCardHeader {
  background-color: #fff;
  width: 100%;
  height: 80px;
  display: flex;
  .left {
    flex: 1;
    display: flex;
    :global {
      .ant-form {
        width: 100%;
        display: flex;
      }
    }
    .showType {
      :global {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      margin-right: 5px;
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .showSelectLabel {
        width: 75px;
      }
      .showSelect {
        flex: 1;
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .single {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      &:last-child {
        padding-right: 0px;
        border-left: 1px solid #eeeeee;
      }
      .num {
        padding: 0px 10px;
        border-radius: 14px;
        border: 1px solid #eeeeee;
        margin-left: 5px;
        background-color: #eeeeee;
      }
    }
  }
}
.notificationCardContent {
  max-height: calc(100vh - 308px);
  overflow-y: scroll;
}
.notificationCardContentPopoverBox {
  max-height: 508px;
  overflow-y: scroll;
  .loading {
    :global {
      .ant-spin {
        margin-left: 50%;
      }
    }
  }
}
.notificationPagination {
  margin-top: 10px;
  text-align: right;
}
.more {
  height: 32px;
  padding: 5px 0px;
  text-align: center;
  border: 1px solid #d9d9d9;
  margin-top: 5px;
  color: #02b980;
  cursor: pointer;
}

.notificationCardBody {
  :global {
    .ant-card-body {
      padding: 0;
    }
  }
}
.notificationCard {
  cursor: pointer;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: relative;
  display: flex;
  .jinJiIcon {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 0px;
    height: 0;
    border-top: 28px solid #ff4d4f;
    border-right: 28px solid transparent;
    .jinJi {
      color: #fff;
      position: absolute;
      top: -27px;
      left: 0px;
    }
  }
  .tongZhiIcon {
    width: 24px;
    height: 24px;
    position: relative;
    margin-right: 16px;
    .icon {
      font-size: 24px;
    }
    .hongDian {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 6px;
      height: 6px;
      background-color: #ff4d4f;
      border: 1px solid #ffffff;
      border-radius: 10px;
    }
    .hongDianNum {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -10px;
      right: -10px;
      padding: 2px;
      height: 22px;
      width: 22px;
      font-size: 12px;
      background: #ff4d4f;
      border: 1px solid #ffffff;
      border-radius: 11px;
      color: #fff;
    }
  }
  .tongZhiContent {
    flex: 1;
    width: 200px;
    .titleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        line-height: 24px;
        .text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .time {
        width: 150px;
        height: 22px;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
      }
    }
    .moduleBox {
      display: flex;
      font-size: 14px;
      padding: 0px;
      .title {
        &:first-child {
          padding: 0px 5px 0px 0px;
          border-right: 1px solid #595959;
        }
        &:last-child {
          padding: 0px 0px 0px 5px;
        }
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .moduleSpan {
        max-width: 25%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child {
          padding: 0px 5px 0px 0px;
        }
        &:last-child {
          padding: 0px 0px 0px 5px;
        }
      }
    }

    .content {
      font-size: 14px;
      text-align: left;
      color: #8c8c8c;
      margin-top: 8px;
      .popoverText {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.help {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .urgentNotice {
    cursor: pointer;
    width: 40px;
    height: 100%;
    margin-right: 50px;
    position: relative;
    .flag {
      display: inline-block;
      font-size: 50px;
      position: absolute;
      top: -5px;
      left: 0;
    }
    .notice {
      width: 50px;
      height: 100%;
      text-align: center;
      font-size: 10px;
      position: absolute;
      top: -16px;
      left: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .message {
    cursor: pointer;
    color: #8c8c8c;
    margin-right: 30px;
    position: relative;
    font-size: 16px;

    .num {
      font-size: 12px;
      padding: 0px 8px;
      position: absolute;
      top: 13px;
      background-color: #ff4d4f;
      left: 8px;
      border-radius: 10px;
      height: 20px;
      line-height: 20px;
      color: #fff;
    }
  }
  .helpCenter {
    color: #8c8c8c;
    cursor: pointer;
    font-size: 16px;
  }
}

.notificationPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0px;
    }
  }
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.message {
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .module {
    margin-bottom: 8px;
    .moduleSpan {
      color: #595959;
      &:first-child {
        padding: 0px 5px 0px 0px;
        // border-right: 1px solid #595959;
      }
      &:last-child {
        padding: 0px 0px 0px 5px;
      }
    }
  }
  .content {
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
  }
}

.urgentNotice {
  :global {
    .ant-notification-notice-close {
      display: none;
    }
  }
}
