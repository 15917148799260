.step-operation-column {
  white-space: nowrap;

  :global {
    .ant-btn-link {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }
    .ant-btn-link[disabled] {
      color: #9ae3cc;
    }
    .ant-btn-link:not(:last-of-type) {
      padding-right: 16px;
    }
  }
}
