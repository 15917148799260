.icons:hover {
  color: #02b980;
}
.iconNode {
  display: none;
}
.treeNode {
  position: relative;
}
.treeNode:hover .iconNode {
  display: inline-flex;
}
