.numberTable {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-table-footer {
      border: 1px dashed #d9d9d9;
      background-color: #fff;
      margin-top: 8px;
    }
  }
}
