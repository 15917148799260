.startNode {
  height: 38px;
  width: 74px;
  border: 1px solid #02b980;
  line-height: 38px;
  text-align: center;
  background-color: #e1faee;
  border-radius: 4px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.boxButton {
  position: absolute;
  top: 12px;
  right: 49px;
}

.approvalObject {
  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}

.flowBuilder {
  :global {
    .flow-builder-action-button::before,
    .flow-builder-action-button::after {
      position: absolute;
      background-color: white;
      content: '';
    }
    .flow-builder-action-button::before {
      width: 2px;
      height: 11px;
    }
    .flow-builder-action-button::after {
      width: 11px;
      height: 2px;
    }
    .flow-builder-action-button img {
      display: none;
    }

    .flow-builder-action-button {
      background: #02b980;
      width: 21px !important;
      height: 21px !important;
    }

    .flow-builder-node {
      position: relative;
      .flow-builder-action-button {
        position: absolute;
        left: 50%;
        transform: translate(-10px);
        margin-top: -27px;
      }
    }

    .flow-builder-branch-node {
      .flow-builder-branch-node__add-button {
        position: relative;
        .flow-builder-action-button {
          // bottom: 0px !important;
          margin-top: 0px;
          position: absolute;
          left: 50%;
          transform: translate(-10px);
        }
      }
    }
  }
}

.flowForm {
  :global {
    .ant-form-item-label {
      min-width: 110px;
      text-align: right;
    }
  }
  .title {
    width: 64px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-bottom: 24px;
  }
}
